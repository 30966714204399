
import { Component, Vue, Watch } from "vue-property-decorator";
import "@fullcalendar/core/vdom"; // solve problem with Vite
import FullCalendar, { CalendarOptions } from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import zhTwLocale from "@fullcalendar/core/locales/zh-tw";
import formatDate from "../../../models/FormatDate";
import calendar from "../../../models/Calendar";
import tippy from "tippy.js";
import tigermaster from "fdtigermaster-client-sdk";
@Component({
  components: {
    FullCalendar, // make the <FullCalendar> tag available
  },
})
export default class Calendar extends Vue {
  $refs!: {
    fullCalendar: any;
  };
  selectClassID = 999;
  holidayData: {
    date: string;
    description: string;
    id: string;
    status: number;
  }[] = [];
  //select
  getCourseData: {
    classroom: string;
    activitySession: {
      type: number | null;
      name: string | null;
      activityStartTime: string;
      activityEndTime: string;
      id: number | null;
      status: number | null;
    };
  }[] = [];
  isWeekday = false;
  isWeekend = false;
  type: string | null = null; //選密集or假日
  classroomWeekday: {
    type: number;
    id: number;
    activitySession: {
      name: string;
      status: number;
      activityStartTime: string;
    };
  }[] = []; //某某校區密集0
  classroomWeekend: {
    type: number;
    id: number;
    activitySession: {
      name: string;
      status: number;
      activityStartTime: string;
    };
  }[] = []; //某某校區假日1
  getCourseData3: {
    type: number;
    id: number;
    activitySession: {
      name: string;
      status: number;
      activityStartTime: string;
    };
  }[] = [
    {
      type: 999,
      id: 999,
      activitySession: {
        name: "請選擇班級",
        status: 999,
        activityStartTime: "99999",
      },
    },
  ]; //用來顯示學院名稱
  classroomList: string[] = []; //顯示select option(不重複)
  classroomSelect: string | null = "臺北校區"; //選擇的classroom
  classList: {
    type: number;
    id: number;
    activitySession: {
      name: string;
      status: number;
      activityStartTime: string;
    };
  }[] = [];
  selectClass: any;
  //data
  isLoading = true;
  selectTypeDone = true;
  selectClassDone = true;
  isClickDownload = false;
  showDownloadBtn = true;
  showTooltip1 = false;
  showTooltip2 = false;
  testEvent: {
    classroom: string;
    id: number | null;
    type: number | null;
    activitySession: { status: number | null; activityStartTime: string };
    academyCourses: {
      courseName: string;
      courseDate: string;
      courseDetail: { detailName: string; period: string; teacher: string }[];
    }[];
  }[] = [];
  testEvent2: {
    classroom: string;
    id: number | null;
    type: number | null;
    activitySession: { status: number | null; activityStartTime: string };
    academyCourses: {
      courseName: string;
      courseDate: string;
      courseDetail: { detailName: string; period: string; teacher: string }[];
    }[];
  }[] = [];
  queryAllEvents: {
    courseName: string;
    courseDate: string;
    weekday?: string;
    color?: string;
    textColor?: string;
    courseDetail: { detailName: string; period: string; teacher: string }[];
  }[] = [];
  events: {
    title: string;
    start: string;
    end: string;
    color?: string;
    textColor?: string;
    extendedProps?: {
      weekday: string;
      courseDetail: { period: string; detailName: string; teacher: string }[];
    };
  }[] = [];
  chooseClassroom: {
    classroom: string;
    type: number | null;
    id: number | null;
    activitySession: {
      status: number | null;
      activityStartTime: string;
    };
    academyCourses: {
      courseName: string;
      courseDate: string;
      color?: string;
      textColor?: string;
      courseDetail: {
        detailName: string;
        period: string;
        teacher: string;
      }[];
    }[];
  }[];
  private calendarOptions: CalendarOptions = {
    plugins: [
      dayGridPlugin,
      timeGridPlugin,
      interactionPlugin, // needed for dateClick
    ],
    initialView: "dayGridMonth",
    headerToolbar: {
      center: "title",
      left: "today",
      right: "prev,next",
    },
    buttonText: {
      today: "今天",
      month: "月",
    },
    locale: zhTwLocale,
    timeZone: "local",
    views: {
      dayGridMonth: {
        dayCellContent(item: { date: any }) {
          let _date = formatDate(item.date).split("-");
          let _dateF = calendar.solar2lunar(
            _date[0],
            _date[1],
            _date[2]
          ) as any;
          return {
            html: `<div style="font-size: 12px;
    line-height: 16px;
    color: #4A4A4A;display: flex;
    align-items: baseline;"><span class="fc-daygrid-day-number" style="display: inline-block;">${_dateF.cDay}</span><span style="margin-left: 4px;" class="lunar">(${_dateF.IDayCn})<span></div>`,
          };
        },
      },
    },    
    eventColor: "#FABF13",
    events:  [
          { title: ''
, date: '2024-01-01' },
          { title: 'event 2222', date: '2024-01-02' }
        ],
    fixedWeekCount: false,
    eventMouseEnter(info: any): void {
      let str = "";
      if (info.event.extendedProps.courseDetail) {
        info.event.extendedProps.courseDetail.forEach(
          (element: {
            period: string;
            detailName: string;
            weekday: string;
          }) => {
            let period = element.period.substr(-11);
            str += `${period}&nbsp${element.detailName}<br/>`;
          }
        );
        let eve = info.event;
        let date = new Date(eve.start);
        let dateStr = date.getMonth() + 1 + "月" + date.getDate() + "日";
        let eveDetial = info.event.extendedProps;
        let span = info.event.backgroundColor;
        tippy(info.el, {
          content: `<div class='tippy'>
            <div style='color: #4F4F54'>
              <span style='display:inline-block;margin-right:14px; width:17px;height:17px;background-color:${span};border-radius: 4px;' ></span>${eve.title}<br/><div style='color: #4F4F54; margin-left:30px'>${dateStr}${eveDetial.weekday}</div></div>
              <div style='display:flex; margin-top:10px;margin-bottom:10px;' >
              <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" style='margin-right:14px;'>
              <path d="M10 12H0V14H10V12ZM16 4H0V6H16V4ZM0 10H16V8H0V10ZM0 0V2H16V0H0Z" fill="#4F4F54" />
              </svg>
              <div> ${str}</div>
              </div>
               <div style='display:flex'>
             <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" style='margin-right:14px;'>
    <path d="M14 11H9V16H14V11ZM13 0V2H5V0H3V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2H15V0H13ZM16 18H2V7H16V18Z" fill="#4F4F54"/></svg><div> ${eveDetial.courseDetail[0].teacher}</div></div>`,
          theme: "light",
          animation: "scale",
          placement: "auto",
          allowHTML: true,
          arrow: true,
        });
      }
    },
  };
  //hook
  created(): any {
    this.Loading();
  }
  async mounted(): Promise<void> {
    // 暫時強制日曆不跑版
    const element1 = document.querySelector(".fc-view-harness") as HTMLElement;
    element1.style.height = "768.889px";
    const element2 = document.querySelector(".fc-col-header") as HTMLElement;
    element2.style.width = "1036px";
    const element3 = document.querySelector(".fc-daygrid-body") as HTMLElement;
    element3.style.width = "1036px";
    const element4 = document.querySelector(".fc-scrollgrid-sync-table") as HTMLElement;
    element4.style.width = "1036px";
    element4.style.height = "994px";
      
    const u = navigator.userAgent;
    //ios click to calendar wil screen freeze
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isiOS) {
      let ios = document.querySelector(".fc-view-harness") as HTMLElement;
      ios.addEventListener("touchstart", (e) => {
        e.preventDefault();
      });
      ios.addEventListener("click", (e) => {
        e.preventDefault();
      });
    }

    this.Loading();
    const holiday = await tigermaster.database
      .query("holiday")
      .where("holiday.date", ">", "2021-12-31")
      .get();
    this.holidayData = holiday.data;
    const res = await tigermaster.database.query("academy_session").get();
    this.testEvent2 = res.data;
    this.sortByStart(this.testEvent2);
    console.log(this.testEvent2[0].academyCourses[0].courseName);
    
    const res2 = await tigermaster.database
      .query("academy_session")
      .where("academy_session.classroom", "=", "臺北校區")
      .get();
    let testEvent3 = res2.data;
    this.sortByStart(testEvent3);
    this.testEvent = testEvent3;
    this.getEvent(); //每節課都列出來
    this.pushEvent(); //每節課對應欄位塞到this.events
    //select classroom 挖出開放中的學院
    this.getCourseData = res.data;
    let courseStatus = this.getCourseData.filter((i) => {
      return i.activitySession.status === 1;
    });
    //抽出classroom
    let room: string[] = [];
    courseStatus.forEach(function (item) {
      room.push(item.classroom);
    });
    //拿掉重複的classroom
    this.classroomList = room.filter((ele, pos) => room.indexOf(ele) == pos);
    //初始用台北
    if (this.classroomSelect === "臺北校區") {
      this.classList = res2.data; //某某校區包含密集0或假日1
      this.separateType();
    }
  }
  //methods
  getEvent(): void {
    //挖出status=1全部的event=>this.testEvent
    this.testEvent = this.testEvent.filter((item) => {
      return item.activitySession.status === 1;
    });
    //挖出status=1的9門課程(academyCourses) =>courseStatus
    let courseStatus: {
      courseName: string;
      courseDate: string;
      courseDetail: { detailName: string; period: string; teacher: string }[];
    }[][] = [];
    this.testEvent.forEach(function (item) {
      courseStatus.push(item.academyCourses);
    });
    //把9門課程內的每節課列出來 =>coursesList
    let coursesList: {
      courseName: string;
      courseDate: string;
      courseDetail: { detailName: string; period: string; teacher: string }[];
    }[] = [];
    courseStatus.forEach(function (item) {
      item.forEach((i) => {
        coursesList.push(i);
      });
    });
    this.queryAllEvents = coursesList;
    console.log('line369',"this.queryAllEvents",this.queryAllEvents)
  }
  pushEvent(): void {
    //this.queryAllEvents加color欄位 =>courseEvents
    let courseEvents: {
      courseName: string;
      courseDate: string;
      color?: string;
      textColor?: string;
      weekday: string;
      courseDetail: { detailName: string; period: string; teacher: string }[];
    }[] = [];
    this.queryAllEvents.forEach((item) => {
      let weekday = this.getDayOfWeek(item.courseDate);
      let coursedate = this.checkDate(item.courseDate);
      courseEvents.push({
        courseName: item.courseName,
        courseDate: coursedate,
        weekday: weekday,
        color: item.color,
        courseDetail: item.courseDetail,
      });
    });
    //courseEvents塞到this.events
    courseEvents.forEach(
      (i: {
        courseDetail: { detailName: string; period: string; teacher: string }[];
        courseName: string;
        courseDate: string;
        weekday?: string;
        color?: string;
        textColor?: string;
      }) => {
        if (i.courseDetail !== undefined && i.color === undefined) {
          this.events.push({
            title: i.courseName,
            start: i.courseDate,
            end: "",
            color: "#FABF13",
            textColor: "#000",
            extendedProps: {
              courseDetail: i.courseDetail,
              weekday: i.weekday as string,
            },
          });
        } else if (i.courseDetail !== undefined && i.color !== undefined) {
          this.events.push({
            title: i.courseName,
            start: i.courseDate,
            end: "",
            color: " #EFF6FC",
            textColor: "#000",
            extendedProps: {
              courseDetail: i.courseDetail,
              weekday: i.weekday as string,
            },
          });
        }
      }
    );
    this.holidayData.forEach(
      (item: {
        date: string;
        description: string;
        id: string;
        status: number;
      }) => {
        this.events.push({
          title: item.description,
          start: item.date,
          end: "",
          color: "#D1D1D1",
          textColor: "#fff",
        });
      }
    );
  }
  //第2個select區分type
  separateType(): void {
    this.classroomWeekend = this.classList.filter(function (element: {
      type: number;
      activitySession: { status: number };
    }) {
      return element.type === 1 && element.activitySession.status === 1;
    }); //濾出某某校區包含假日1
    this.classroomWeekday = this.classList.filter(function (element: {
      type: number;
      activitySession: { status: number };
    }) {
      return element.type === 0 && element.activitySession.status === 1;
    }); //濾出某某校區包含假日1
    if (this.classroomWeekday.length > 0 && this.classroomWeekend.length > 0) {
      this.isWeekend = true;
      this.isWeekday = true;
    }
    if (
      this.classroomWeekend.length > 0 &&
      this.classroomWeekday.length === 0
    ) {
      this.isWeekend = true;
      this.isWeekday = false;
    }
    if (
      this.classroomWeekday.length > 0 &&
      this.classroomWeekend.length === 0
    ) {
      this.isWeekend = false;
      this.isWeekday = true;
    }
    if (
      this.classroomWeekday.length === 0 &&
      this.classroomWeekend.length === 0
    ) {
      this.isWeekend = false;
      this.isWeekday = false;
    }
    this.chooseClassroom = this.testEvent.filter(
      (item) => item.classroom === this.classroomSelect
    );

    let weekendList = this.chooseClassroom.filter((item) => {
      return item.type === 1;
    });
    weekendList.forEach(function (item) {
      item.academyCourses.forEach(function (item) {
        item.color = " #EFF6FC";
        item.textColor = "#000";
      });
    });
    let courses: {
      courseName: string;
      courseDate: string;
      courseDetail: { detailName: string; period: string; teacher: string }[];
    }[][] = [];
    this.chooseClassroom.forEach(function (item) {
      courses.push(item.academyCourses);
    });
    let queryAllEvents: {
      courseName: string;
      courseDate: string;
      weekday?: string;
      courseDetail: { detailName: string; period: string; teacher: string }[];
    }[] = [];
    courses.forEach(function (item) {
      if (item.length > 0) {
        item.forEach(function (item2) {
          queryAllEvents.push(item2);
        });
      }
    });
    this.queryAllEvents = queryAllEvents;
    this.events.splice(0, this.events.length);
    this.pushEvent();
  }
  //select watch
  @Watch("classroomSelect")
  private async watchclassroomSelect() {
    //reset
    this.Loading();
    this.selectTypeDone = true;
    this.showTooltip1 = false;
    this.selectClassDone = true;
    this.showTooltip2 = false;
    this.isClickDownload = false;
    this.showDownloadBtn = true;
    this.selectClassID = 999;
    this.type = null;
    this.getCourseData3 = [
      {
        type: 999,
        id: 999,
        activitySession: {
          name: "請選擇班級",
          status: 999,
          activityStartTime: "99999",
        },
      },
    ];
    //reset
    this.testEvent = this.testEvent2;
    this.getEvent();
    if (this.classroomSelect) {
      const res = await tigermaster.database
        .query("academy_session")
        .where("academy_session.classroom", "=", this.classroomSelect)
        //.where("academy_session.type", "IN", [0, 1] as any)
        .get();
      this.classList = res.data; //某某校區包含密集0或假日1
      this.sortByStart(this.classList);
      this.separateType();
    }
  }
  @Watch("type")
  private watchType() {
    let selected = document.querySelector(".type-select") as HTMLElement;
    if (this.type !== null) {
      selected.style.color = "#000";
    } else {
      selected.style.color = "#81868C";
    }
    let disabled = document.querySelector(
      ".class-select option[value]"
    ) as HTMLElement;
    disabled.setAttribute("disabled", "");
    //reset
    this.Loading();
    this.isClickDownload = false;
    this.selectTypeDone = true;
    this.showTooltip1 = false;
    this.selectClassDone = true;
    this.showTooltip2 = false;
    this.showDownloadBtn = true;
    this.selectClassID = 999;
    this.getCourseData3 = [
      {
        type: 999,
        id: 999,
        activitySession: {
          name: "請選擇班級",
          status: 999,
          activityStartTime: "99999",
        },
      },
    ];
    //rest
    let queryAllEvents: {
      courseName: string;
      courseDate: string;
      color?: string;
      textColor?: string;
      courseDetail: { detailName: string; period: string; teacher: string }[];
    }[] = [];
    if (this.type === "1") {
      this.classroomWeekend.forEach((item) => {
        this.getCourseData3.push(item);
      });
      let sss = this.chooseClassroom.filter((item) => {
        return item.type === 1;
      });
      sss.forEach(function (item) {
        if (item.academyCourses.length > 0) {
          item.academyCourses.forEach(function (item) {
            item.color = " #EFF6FC";
            item.textColor = "#000";
            queryAllEvents.push(item);
          });
        }
      });
    }
    if (this.type === "0") {
      this.classroomWeekday.forEach((item) => {
        this.getCourseData3.push(item);
      });
      let sss = this.chooseClassroom.filter((item) => {
        return item.type === 0;
      });
      sss.forEach(function (item) {
        if (item.academyCourses.length > 0) {
          item.academyCourses.forEach(function (item2) {
            queryAllEvents.push(item2);
          });
        }
      });
    }
    this.queryAllEvents = queryAllEvents;
    this.events.splice(0, this.events.length);
    this.pushEvent();
  }
  @Watch("selectClassID")
  private async watchclassId() {
    let selected = document.querySelector(".class-select") as HTMLElement;
    if (this.selectClassID !== 999) {
      selected.style.color = "#000";
    } else {
      selected.style.color = "#81868C";
    }
    //restet
    this.Loading();
    this.showDownloadBtn = false;
    this.selectClassDone = true;
    this.showTooltip2 = false;
    //restet
    this.selectClass = this.getCourseData3.filter((item) => {
      return item.id === this.selectClassID;
    });

    if (this.selectClass[0].id !== 999) {
      let queryAllEvents: {
        courseName: string;
        courseDate: string;
        color?: string;
        textColor?: string;
        courseDetail: { detailName: string; period: string; teacher: string }[];
      }[] = [];
      if (this.selectClass[0].type === 1) {
        this.selectClass[0].academyCourses.forEach(function (item: {
          courseName: string;
          courseDate: string;
          color?: string | undefined;
          textColor?: string;
          courseDetail: {
            detailName: string;
            period: string;
            teacher: string;
          }[];
        }) {
          item.color = " #EFF6FC";
          item.textColor = "#000";
          queryAllEvents.push(item);
        });
      }
      this.queryAllEvents = this.selectClass[0].academyCourses;
      this.events.splice(0, this.events.length);
      this.pushEvent();
      this.gotoDate();
    } else {
      let calendarApi = this.$refs.fullCalendar.getApi();
      calendarApi.today();
    }
  }
  @Watch("isClickDownload")
  private watchisClickDownload() {
    if (this.isClickDownload === true && this.type === null) {
      this.selectTypeDone = false;
      this.selectClassDone = false;
      this.showTooltip1 = true;
      this.isClickDownload = false;
      this.showTooltip2 = true;
      this.isClickDownload = false;
    }
    if (this.isClickDownload === true) {
      this.selectClassDone = false;
      this.showTooltip2 = true;
      this.isClickDownload = false;
    }
  }
  checkDate(data: string): string {
    return data.substr(0, 10);
  }
  Loading(): void {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  }
  getDayOfWeek(dateStr: string): string {
    var dayOfWeek = "";
    dateStr = dateStr.replace(/-/g, "/");
    var date = new Date(dateStr);
    switch (date.getDay()) {
      case 0:
        dayOfWeek = "(星期日)";
        break;
      case 1:
        dayOfWeek = "(星期一)";
        break;
      case 2:
        dayOfWeek = "(星期二)";
        break;
      case 3:
        dayOfWeek = "(星期三)";
        break;
      case 4:
        dayOfWeek = "(星期四)";
        break;
      case 5:
        dayOfWeek = "(星期五)";
        break;
      case 6:
        dayOfWeek = "(星期六)";
        break;
    }
    return dayOfWeek;
  }
  GMTToStr(time: string | number | Date): string {
    let date = new Date(time);
    let Str =
      date.getFullYear() +
      "年" +
      (date.getMonth() + 1) +
      "月" +
      date.getDate() +
      "日";
    return Str;
  }
  clickDownload(): void {
    this.isClickDownload = true;
  }
  dateStr(): string {
    let nowDate = new Date();
    let year = nowDate.getFullYear();
    let month =
      nowDate.getMonth() + 1 < 10
        ? "0" + (nowDate.getMonth() + 1)
        : nowDate.getMonth() + 1;
    let day =
      nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
    let dateStr = year + "-" + month + "-" + day;
    return dateStr;
  }
  sortByStart(item: any[]) {
    item.sort(function (a, b) {
      return (
        parseInt(
          a.activitySession.activityStartTime.substr(5, 2) +
            a.activitySession.activityStartTime.substr(8, 2)
        ) -
        parseInt(
          b.activitySession.activityStartTime.substr(5, 2) +
            b.activitySession.activityStartTime.substr(8, 2)
        )
      );
    });
  }
  gotoDate(): void {
    let calendarApi = this.$refs.fullCalendar.getApi();
    let month = this.selectClass[0].activitySession.name.substr(0, 2);
    let year = new Date().getFullYear();
    calendarApi.gotoDate(`${year}-${month}-01`);
  }
}
